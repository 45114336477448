import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/seo/seo'
// markup
const IndexPage = () => {
  return (
    <>
      <Seo
        title='Sayın Textile'
        description='Sayın textile'
        article
        lang='en'
      />
      <div
        className='w-full h-screen bg-cover bg-no-repeat bg-center flex flex-wrap item-center justify-center'
        style={{
          backgroundImage: 'url(' + '/images/home-intro-black.jpg' + ')',
        }}
      >
        <div className='max-w-screen-lg mx-auto my-auto h-60'>
          <div className='w-full '>
            <StaticImage
              src='../images/sayintextile-big.png'
              alt='sayın textile logo'
              width='739'
              height='168'
            />
          </div>

          <div className='w-full flex items-center justify-center'>
            <Link
              to='/hosgeldiniz/'
              className='border border-gray-600 text-gray-900 px-5 py-2 fond-semibold mx-2 text-lg bg-gray-100 bg-opacity-50 md:bg-transparent hover:border-white hover:text-white hover:bg-gray-700 hover:bg-opacity-40'
              hrefLang='tr'
            >
              TÜRKÇE
            </Link>
            <Link
              to='/wellcome/'
              className='border border-gray-600 text-gray-900 px-5 py-2 fond-semibold mx-2 text-lg bg-gray-100 bg-opacity-50 md:bg-transparent hover:border-white hover:text-white hover:bg-gray-700 hover:bg-opacity-40'
              hrefLang='en'
            >
              ENGLISH
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexPage
